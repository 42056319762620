var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "div",
          { staticClass: "box-content" },
          [
            _c(
              "b-form-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("ACTIVATION_CODE") },
              },
              [
                _c("b-form-input", {
                  attrs: {
                    trim: "",
                    required: "",
                    type: "text",
                    name: "activation_token",
                  },
                  model: {
                    value: _vm.ActivateNodeDTO.activation_token,
                    callback: function ($$v) {
                      _vm.$set(_vm.ActivateNodeDTO, "activation_token", $$v)
                    },
                    expression: "ActivateNodeDTO.activation_token",
                  },
                }),
              ],
              1
            ),
            _c("h6", { staticClass: "mt-2" }, [
              _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE"))),
            ]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.$t("NAME") } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "organisation_name",
                          },
                          model: {
                            value: _vm.ActivateNodeDTO.organisation_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ActivateNodeDTO,
                                "organisation_name",
                                $$v
                              )
                            },
                            expression: "ActivateNodeDTO.organisation_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("ORGANISATION.UNIQUE_IDENTIFIER"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "organisation_unique_identifier",
                          },
                          model: {
                            value:
                              _vm.ActivateNodeDTO
                                .organisation_unique_identifier,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ActivateNodeDTO,
                                "organisation_unique_identifier",
                                $$v
                              )
                            },
                            expression:
                              "ActivateNodeDTO.organisation_unique_identifier",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("h6", { staticClass: "mt-2" }, [
              _vm._v(_vm._s(_vm.$t("ORGANISATION.ADMINISTRATOR"))),
            ]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.$t("NAME") } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "name",
                          },
                          model: {
                            value: _vm.ActivateNodeDTO.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ActivateNodeDTO, "name", $$v)
                            },
                            expression: "ActivateNodeDTO.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.$t("EMAIL") } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            trim: "",
                            required: "",
                            type: "text",
                            name: "email",
                          },
                          model: {
                            value: _vm.ActivateNodeDTO.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.ActivateNodeDTO, "email", $$v)
                            },
                            expression: "ActivateNodeDTO.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mt-2",
                attrs: { label: _vm.$t("LANGUAGE.TITLE") },
              },
              [
                _c(
                  "b-form-select",
                  {
                    attrs: { name: "activate_select_language" },
                    model: {
                      value: _vm.ActivateNodeDTO.language,
                      callback: function ($$v) {
                        _vm.$set(_vm.ActivateNodeDTO, "language", $$v)
                      },
                      expression: "ActivateNodeDTO.language",
                    },
                  },
                  _vm._l(_vm.sefos_locales, function (value, key) {
                    return _c(
                      "b-form-select-option",
                      { key: key, attrs: { value: key } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("LANGUAGE." + key)) +
                            "\n          "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "mt-4" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("AFTER_ACTIVATION")) + "\n      "
              ),
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill btn-block btn-primary",
                  attrs: { disabled: _vm.disabled },
                  on: { click: _vm.activate },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("SUBMIT")) + "\n        "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }