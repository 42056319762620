<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div class="box-content">
        
        <b-form-group :label="$t('ACTIVATION_CODE')" class="mb-4">
          <b-form-input trim
            required
            type="text"
            v-model="ActivateNodeDTO.activation_token"
            name="activation_token"
          ></b-form-input>
        </b-form-group>

        <h6 class="mt-2">{{ $t("ORGANISATION.TITLE") }}</h6>

        <b-row>
          <b-col>
            <b-form-group :label="$t('NAME')">
              <b-form-input trim
                required
                type="text"
                v-model="ActivateNodeDTO.organisation_name"
                name="organisation_name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('ORGANISATION.UNIQUE_IDENTIFIER')"
              class="mb-4"
            >
              <b-form-input trim
                required
                type="text"
                v-model="ActivateNodeDTO.organisation_unique_identifier"
                name="organisation_unique_identifier"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <h6 class="mt-2">{{ $t("ORGANISATION.ADMINISTRATOR") }}</h6>
        <b-row>
          <b-col>
            <b-form-group :label="$t('NAME')">
              <b-form-input trim
                required
                type="text"
                v-model="ActivateNodeDTO.name"
                name="name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('EMAIL')">
              <b-form-input trim
                required
                type="text"
                v-model="ActivateNodeDTO.email"
                name="email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="mt-2" :label="$t('LANGUAGE.TITLE')">
          <b-form-select  name="activate_select_language" v-model="ActivateNodeDTO.language">
            <b-form-select-option
              v-for="(value, key) in sefos_locales"
              :key="key"
              :value="key"
            >
              {{ $t("LANGUAGE." + key) }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        
        <p class="mt-4">
          {{ $t("AFTER_ACTIVATION") }}
        </p>

        <div class="mt-4">
          <button
            :disabled="disabled"
            v-on:click="activate"
            class="btn btn-fill btn-block btn-primary"
          >
            {{ $t("SUBMIT") }}
          </button>
        </div>
      </div>
    </b-overlay>
  </section>
</template>
<script>

export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      ActivateNodeDTO: {
        email: "",
        name: "",
        organisation_name: "",
        organisation_unique_identifier: "",
        activation_token: "",
        language: this.$i18n.locale
      }
    };
  },
  methods: {
    async activate() {
      this.loading = true;
      try {
        await this.$http.post(this.user.hostname + "/node/activate", this.ActivateNodeDTO );
        this.loading = false;
        this.$noty.info(this.$t("ACTIVATED"));
        this.$router.push("/activated");
      } catch {
        this.loading = false;
        console.log("Could not activate");
      }
    },
    async checkActivated() {
      try {
        await this.$http.get(this.user.hostname + "/isActivated");
        this.loading = false;
        this.$router.push("/activated");        
      } catch {
        this.loading = false;
        console.log("Could not checkActivated");
      }
    },
  },
  computed: {
    disabled() {
      if (this.ActivateNodeDTO.activation_token == "") {
        return true;
      }
      if (this.ActivateNodeDTO.organisation_name == "") {
        return true;
      }
      if (this.ActivateNodeDTO.organisation_unique_identifier == "") {
        return true;
      }
      if (this.ActivateNodeDTO.name == "") {
        return true;
      }
      if (this.ActivateNodeDTO.email == "") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.loading = true;
    this.checkActivated();
  },
};
</script>
<style></style>
